import { useEffect } from "react"
import { navigate } from "gatsby"

const eventListenerForRedirect = ({ orderData, backButton = () => {} }) => {
    useEffect(() => {
        // Eventlistener will listen for the Netlify function message
        const eventListenerFunction = (event) => {
            let eventData = event.data
            let eventMessage = event.data
            try {
                eventData = JSON.parse(event.data)
            } catch {}
            try {
                eventMessage = eventData.message
            } catch {}

            const orderStatusUrl = buildOrderStatusUrl(orderData)

            if (eventMessage.includes('/order-success/')) {
                navigate(orderStatusUrl)
            }
        }

        if (typeof window != "undefined") {
            window.addEventListener("message", eventListenerFunction)
            return () => window.removeEventListener("message", eventListenerFunction)
        }
    }, [orderData])
}

// Backup - Watch the iFrame URL and redirect if the URL indicates it as necessary
// NOTE: This code is mainly a fallback which allows localhost development to work
//       withouth a Netlify functions running.
export const handleIFrameChange = (
    orderData,
    iFrameName,
    setIframeStatus = () => {}
) => {
    const iFrame = window.document.getElementById(iFrameName)
    let iFrameHref = ""
    // This will throw an exception if the iFrame and parent domains differ
    try {
        iFrameHref =
            iFrame &&
            iFrame.contentWindow &&
            iFrame.contentWindow.location &&
            iFrame.contentWindow.location.href
    } catch {}
    if (
        iFrameHref.includes("/order-success/")
    ) {
        const orderStatusUrl = buildOrderStatusUrl(orderData)
        navigate(orderStatusUrl)
    }
    setIframeStatus(true)
}

export const buildOrderStatusUrl = (order) => {
    if (order && order.id) {
        return `/order-success/?orderid=${order.id}`
    } else {
        return `/profile/`
    }
}

export default eventListenerForRedirect
