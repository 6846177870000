/* eslint-disable no-empty */
/* eslint-disable no-eval */
/* eslint-disable no-use-before-define */
import React, {useState, useEffect, useContext} from 'react'
import {navigate} from 'gatsby'
import {Helmet} from 'react-helmet'
import querystring from 'querystring'
import Form from 'semantic-ui-react/dist/commonjs/collections/Form'
import Message from 'semantic-ui-react/dist/commonjs/collections/Message'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox'
import styled from 'styled-components'
import CartContext from '../../Context/CartContext'
import AuthContext from '../../Context/AuthContext'
import {formatMoney, scrollToTop} from '../../../utils'
import LogosPaymentCards from '../../../images/payment-cards-accepted.jpg'
import IconLockLarge from '../../../images/icons/icon-lock-large.svg'
import {getUser, isLoggedIn} from '../../../services/auth'
import {createPayment, getOrder} from '../../../utils/WordPress'
import {analyticsPaymentMethod} from '../../../services/GoogleAnalytics'
import {isOnMobile} from "../../../utils";
import eventListenerForRedirect, { handleIFrameChange } from './eventListenerForRedirect'

const PaymentOptions = styled.div`
  .payment-continue {
    display: flex;
    flex-direction: column;
    align-items: center;
    .field {
      width: 350px;
      margin: 10px auto;
      .ui.radio {
        border: 1px solid #ccc;
        background: #eee;
        display: block;
        padding: 20px;
        border-radius: 8px;
      }
      .ui.checked {
        border: 1px solid ${({theme}) => theme.red};
        background: #fff;
        font-weight: 700;
        label:before {
          border: 1px solid ${({theme}) => theme.red};
        }
        label:after {
          background-color: ${({theme}) => theme.red} !important;
        }
      }
    }
  }
  .message-container {
    max-width: 350px;
    margin: 10px auto;
  }
`

const paymentOpts = [
  {code: 'creditCard', label: 'Credit / Cheque Card'},
  // {code: 'cashOnDel', label: 'Cash on delivery'},
  // {code: 'ccOnDel', label: 'Credit Card on delivery'},
  {code: 'manualEft', label: 'Manual EFT'},
  {code: 'peachEft', label: 'Instant EFT'},
  {code: 'float', label: 'Pay with Float'}
]

// if (isLoggedIn()){
//   const {user_email} = getUser()
//   if (user_email === 'divan@themattresswarehouse.co.za') {
//     paymentOpts.push({code: 'float', label: 'Pay with Float'})
//   }
// }

const Payment = ({location, setProcessingModal, givenOrderId, givenOrder}) => {
  const [option, setOption] = useState(paymentOpts[0].code)
  const [optionConfirmed, setOptionConfirmed] = useState(false)
  const [billingError, setBillingError] = useState('')
  const [orderData, setOrderData] = useState(null)
  const [paymentData, setPaymentData] = useState(null)
  const [waitingForPeach, setWaitingForPeach] = useState(false)
  const {cartMeta, cartReady} = useContext(CartContext)
  const {performLogout} = useContext(AuthContext)
  const [iframeReady, setIframeReady] = useState(false);
  const [floatResponse, setFloatResponse] = useState(null)

  if (!isLoggedIn()) {
    return (
      <Message
        error
        content="You have been logged out. Please log in to continue"
      />
    )
  }

  // Fetch the order details
  const fetchOrder = async queryOrderId => {
    const {token} = getUser()
    setProcessingModal(true)

    // Verify the order ID is valid and verify that the order status is "unpaid"
    try {
      const orderData = await getOrder(queryOrderId, token, performLogout)

      // Setting order data will render the PeachPay component which will trigger the fetch of payment data
      if (!orderData.date_paid) {
        setOrderData(orderData)
        setBillingError('')
      } else {
        setBillingError(
          'This order has already been processed. Please contact us for any support.',
        )
      }
      setProcessingModal(false)
    } catch (e) {
      console.log(e)
      if (e && e.errors && e.errors[0] && e.errors[0].detail) {
        setBillingError(e.errors[0].detail)
      } else {
        setBillingError(
          'An error occured while attempting to provide a payment gateway. Please contact support',
        )
      }
      setProcessingModal(false)
    }
  }

  // Request a new payment from heroku
  const requestPayment = async () => {
    scrollToTop()
    setWaitingForPeach(true)
    setProcessingModal(true)
    const {token} = getUser()

    // In case a previous event listener was added for Peach Pay, remove the old one
    window.removeEventListener('message', peachPayEventListenerFunction)

    const paymentObject = {
      host_url: location.origin,
      selected_payment_method: option,
      ...orderData,
    }

    try {
      const paymentResponse = await createPayment(
        paymentObject,
        token,
        performLogout,
      )
      setPaymentData(paymentResponse)
      setBillingError('')
      paymentRequestDone(paymentResponse)
    } catch (responseErr) {
      setProcessingModal(false)
      try {
        setBillingError(responseErr.message)
      } catch (err) {
        setBillingError(
          'Failed to reach payment gateway. Please try again or contact support',
        )
      }
    }
  }

  // At page load
  useEffect(() => {
    // If an order is given as queryfield and we don't have the orderData yet
    let queryOrderId = null
    if (location.search.includes('?')) {
      const queryval = querystring.parse(location.search.split('?')[1])
      queryOrderId = queryval && queryval.orderid
    }
    // Need to be logged in, and fetch order data if we don't have it
    if (queryOrderId && !orderData && isLoggedIn()) {
      fetchOrder(queryOrderId)
    }
  }, [])

  // Once the payment option has been confirmed and we have the orderData
  useEffect(() => {
    if (orderData && optionConfirmed) {
      requestPayment(orderData)
    }
  }, [optionConfirmed])
  // Once the orderData has been fetched, set optionConfirmed
  useEffect(() => {
    if (givenOrderId) {
      fetchOrder(givenOrder)
    } else if (givenOrder) {
      setOrderData(givenOrder)
      setOptionConfirmed(true)
      setAnalyticsPaymentOption(option)
    }
  }, [givenOrderId, givenOrder])

  const paymentRequestDone = responseData => {
    if (option === 'creditCard') {
      waitForPeachCard()
    } else if (option === 'peachEft') {
      setWaitingForPeach(false)
      // ******* Custom JS code required by Peachpay Secure EFT
      window.addEventListener('message', peachPayEventListenerFunction)
      window.eftSec.checkout.init({
        paymentKey: responseData.payment_key,
        onLoad: () => {
          setProcessingModal(false)
        },
        onHideFrame: () => {
          setProcessingModal(false)
          setOptionConfirmed(false)
        },
      })
      // *******
    } else if (option === 'float') {
      setFloatResponse(responseData)
      setProcessingModal(false)
    } else {
      navigate(`/order-success?orderid=${orderData.id}`)
    }
  }

  const peachPayEventListenerFunction = event => {
    if (event.data !== undefined) {
      try {
        eval(event.data) // handle frame events from child frame
      } catch {}
    }
  }
  // This function checks if the PeachPay card widget exists
  // This is added because their widget can take very long
  const waitForPeachCard = () => {
    // It's there. Disable loading screen
    if (document.querySelector('.wpwl-container')) {
      setProcessingModal(false)
      setWaitingForPeach(false)
      scrollToTop()
      // Not there. Check again in 300 milliseconds
    } else {
      setTimeout(() => {
        waitForPeachCard()
      }, 300)
    }
  }

  const showFloatIframe = () => {
    if (option === 'float' && !billingError) {

      return (
          <>
            {!iframeReady && (
                <p className="message info">
                  Please bear with us for a couple of seconds as we set-up your
                  gateway to process your payment.
                </p>
            )}
            {floatResponse !== null && (
                <iframe
                    width="100%"
                    height={(isOnMobile()) ? "1170px" : "850px"}
                    name="float-payment-iframe"
                    id="float-payment-iframe"
                    src={floatResponse.redirect}
                    onLoad={() =>
                        handleIFrameChange(
                            orderData,
                            "float-payment-iframe",
                            setIframeReady
                        )
                    }
                    style={{border: "1px solid #e5e7eb"}}
                >
                  <p>iframes are not supported by your browser.</p>
                </iframe>
            )}
          </>
      )
    }
  }

  const paymentOptionData = () => {
    if (option === 'creditCard') {
      return (
        <>
          {paymentData && paymentData.data_brands && (
            <>
              <Helmet>
                <script src={paymentData.url} />
              </Helmet>
              <form
                action={`${location.origin}/order-success?orderid=${orderData &&
                  orderData.id}`}
                data-brands={paymentData && paymentData.data_brands}
                className="paymentWidgets"
              />
              <Button
                color="red"
                content="Back"
                onClick={() => {
                  setOptionConfirmed(false)
                }}
              />
            </>
          )}
          {waitingForPeach && (
            <p className="message info">
              Please bear with us for a couple of seconds as we set-up your
              gateway to process your payment.
            </p>
          )}
        </>
      )
    }
    if (option === 'peachEft' && !billingError) {
      return (
        <p className="message info">
          Please bear with us for a couple of seconds as we set-up your payment
          gateway to process your payment.
        </p>
      )
    }
    return null
  }

  const setAnalyticsPaymentOption = () => {
    let optionName = ''
    switch (option) {
      case 'creditCard':
        optionName = 'Credit / Cheque Card'
        break
      case 'peachEft':
        optionName = 'Instant EFT'
        break
      case 'manualEft':
        optionName = 'Manual EFT'
        break
      case 'cashOnDel':
        optionName = 'Cash on delivery'
        break
      case 'ccOnDel':
        optionName = 'Credit Card on delivery'
        break
      case 'float':
        optionName = 'Pay with Float'
        break
      default:
        optionName = 'Invalid payment option'
    }
    analyticsPaymentMethod(optionName)
  }

  return (
    <PaymentOptions>
      <Helmet>
        <script
          type="text/javascript"
          src="https://code.jquery.com/jquery-1.12.4.min.js"
        />
        <script
          type="text/javascript"
          src="https://eft.ppay.io/ext/checkout/v2/checkout.js"
        />
      </Helmet>
      <div className="cart-message-inner">
        <h3>
          <img src={IconLockLarge} alt="" className="lock" />
          We are ready to process your order for{' '}
          <strong>
            {orderData && formatMoney(orderData.total)}
            {!orderData && cartMeta && formatMoney(cartMeta.total)}
          </strong>
        </h3>
        {!optionConfirmed && (
          <p>
            Please select a payment method below to continue placing your order.
          </p>
        )}
        {optionConfirmed ? (
            <>
              {option === 'float' ? (
                  <div className="float-iframe-container">{showFloatIframe()}</div>
              ) : (
                <div className="message-container">{paymentOptionData()}</div>
              )}
            </>
        ) : (
          <Form>
            <div className="payment-continue">
              {paymentOpts.map(opt => {
                return (
                  <Form.Field key={opt.code}>
                    <Checkbox
                      key={opt.code}
                      radio
                      label={opt.label}
                      name="checkboxRadioGroup"
                      value={opt.code}
                      checked={option === opt.code}
                      onChange={(e, {value}) => setOption(value)}
                    />
                  </Form.Field>
                )
              })}
              {orderData && (
                <Button
                  content="Continue"
                  color="red"
                  onClick={() => {
                    setOptionConfirmed(true)
                  }}
                />
              )}
            </div>
          </Form>
        )}
        {billingError && <Message error content={billingError} />}
        {/* {orderData && <OrderItem givenOrder={orderData} />} */}
        <div className="logos">
          <img src={LogosPaymentCards} alt="Payments Cards Accepted" />
        </div>
      </div>
    </PaymentOptions>
  )
}

export default Payment