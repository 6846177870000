/* eslint-disable camelcase */
import React, {useEffect, useState, memo} from 'react'
import {useStaticQuery, graphql, Link} from 'gatsby'
import CartAccessory from '../CartAccessory'
import {requestStock} from '../../../utils/WordPress'

const CartAccessoriesList = memo(({addToCart}) => {
  const [retval, setRetval] = useState([])

  const cartAccessoriesQuery = useStaticQuery(graphql`
    {
      cartAccessories: allWcProducts(
        filter: {
          attributes: {
            elemMatch: {name: {eq: "ShowInCart"}, options: {eq: "true"}}
          }
          catalog_visibility: {eq: "visible"}
          status: {eq: "publish"}
        }
      ) {
        edges {
          node {
            wordpress_id
            name
            stock_quantity
            categories {
              name
            }
            images {
              src
            }
            price
          }
        }
      }
    }
  `)

  useEffect(() => {
    async function wrapCartAccesoriesFunction() {
      const cartAccessoriesEdge = cartAccessoriesQuery.cartAccessories.edges
      for (
        var edge_index = 0;
        edge_index < cartAccessoriesEdge.length;
        ++edge_index
      ) {
        const productIDstockCheck =
          cartAccessoriesEdge[edge_index].node.wordpress_id
        const stockRequest = await requestStock(productIDstockCheck)
        const stockLevel =
          stockRequest[cartAccessoriesEdge[edge_index].node.wordpress_id]
            .stock_quantity
        cartAccessoriesEdge[edge_index].node.stockLevelChart = stockLevel
      }
      var tempStockCheckCartAccessories = []
      for (var index = 0; index < cartAccessoriesEdge.length; ++index) {
        var stockInCartAccessories =
          cartAccessoriesEdge[index].node.stockLevelChart
        if (stockInCartAccessories > 0) {
          var inStockCartAccessories = cartAccessoriesEdge[index]
          tempStockCheckCartAccessories.push(inStockCartAccessories)
        }
      }

      let tempRetval = []
      var accessoryInStockCount = tempStockCheckCartAccessories.length
      for (var index = 0; index < accessoryInStockCount; index += 2) {
        var edge_1 = tempStockCheckCartAccessories[index]
        var edge_2 = false
        let is_double_accessory = index !== accessoryInStockCount - 1
        let cell_class = 'accessory-cell'
        if (is_double_accessory) {
          var edge_2 = tempStockCheckCartAccessories[index + 1]
          cell_class = cell_class + ' accessory-cell-in-2-col'
        } else {
          cell_class = cell_class + ' accessory-cell-in-1-col'
        }
        var item = (
          <div key={'accessory-table'} className={'accessory-table'}>
            <div key={'accessory-row'} className={'accessory-row'}>
              <div
                key={edge_1.node.wordpress_id}
                className={`${cell_class} accessory-cell-left`}
              >
                <CartAccessory
                  key={edge_1.node.wordpress_id}
                  product={edge_1.node}
                  addCallback={addToCart}
                />
              </div>
              {is_double_accessory && (
                <div
                  key={edge_2.node.wordpress_id}
                  className={`${cell_class} accessory-cell-right`}
                >
                  <CartAccessory
                    key={edge_2.node.wordpress_id}
                    product={edge_2.node}
                    addCallback={addToCart}
                  />
                </div>
              )}
            </div>
          </div>
        )
        tempRetval.push(item)
      }
      setRetval(tempRetval)
    }
    wrapCartAccesoriesFunction()
  }, [])

  return (
    <>
      {retval.length ? (
        <>
          <h3>These products may go well with your order:</h3>
          {retval}
        </>
      ) : null}
    </>
  )
})
export default CartAccessoriesList
